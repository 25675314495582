import axios from 'axios'
import store from '../store/index'
import {router} from '../main'
import Vue from 'vue'
import { Message } from 'element-ui';

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
// console.log(process.env.VUE_APP_BASE_URL);
// const request = axios.create();
request.interceptors.request.use(function (config) {

  if(process.env.VUE_APP_ENV == 'dev'){

    console.log('request intercepter called');

  }

  if(store.state.loggedIn){

    config.headers["Authorization"] = `Bearer ${store.state.user.token}`;
    config.headers["X-Store-Id"] = `${store.state.user.store_id}`;
    return config;

  }

  return config;

}, function (error) {

  return Promise.reject(error);
  
});


request.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;

}, function (error) {

  // Do something with request error
  if(error.response && error.response.status === 401){

    function showErrorMessage(message){

      if(!document.querySelector('.el-message')){

        Message.error(message);
        
      }

    }

    showErrorMessage('Session expired, please log in again.');

    let user = Vue.$cookies.get('meri_web_shop.current_user');

    if(user){

      Vue.$cookies.remove('meri_web_shop.current_user');

    }

    store.commit('accounts_module/CLEAR_MODULE');
    store.commit('bills_module/CLEAR_MODULE');
    store.commit('blogCategories_module/CLEAR_MODULE');
    store.commit('blogs_module/CLEAR_MODULE');
    store.commit('content_blocks/CLEAR_MODULE');
    store.commit('coupons_module/CLEAR_MODULE');
    store.commit('customers_module/CLEAR_MODULE');
    store.commit('expenses_module/CLEAR_MODULE');
    store.commit('bar_graph_module/CLEAR_MODULE');
    store.commit('pie_graph_module/CLEAR_MODULE');
    store.commit('line_graph_module/CLEAR_MODULE');
    store.commit('home_banner_module/CLEAR_MODULE');
    store.commit('gallery_module/CLEAR_MODULE');
    store.commit('notifications_module/CLEAR_MODULE');
    store.commit('orders_module/CLEAR_MODULE');
    store.commit('productCategories_module/CLEAR_MODULE');
    store.commit('products_module/CLEAR_MODULE');
    store.commit('redirect/CLEAR_MODULE');
    store.commit('robot/CLEAR_MODULE');
    store.commit('seo_config/CLEAR_MODULE');
    store.commit('settings_module/CLEAR_MODULE');
    store.commit('shipping_module/CLEAR_MODULE');
    store.commit('dashboard_stats/CLEAR_MODULE');
    store.commit('order_stats/CLEAR_MODULE');
    store.commit('product_stats/CLEAR_MODULE');
    store.commit('store_categories_module/CLEAR_MODULE');
    store.commit('themes_module/CLEAR_MODULE');
    store.commit('subscription_module/CLEAR_MODULE');
    store.commit('vendor_module/CLEAR_MODULE');
    store.commit('page_details_module/CLEAR_MODULE');

    store.dispatch('authLoggedOut');

    router.replace('/login').catch(() => {});

  }

  throw error

});


export default request;

